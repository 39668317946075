// extracted by mini-css-extract-plugin
export var nav = "style-module--nav--KwwBt";
export var primary = "style-module--primary--VL0Rf";
export var primaryPad = "style-module--primaryPad--B4fgH";
export var secondary = "style-module--secondary--jWk91";
export var primaryLi = "style-module--primaryLi--pM0+H";
export var primaryLiWithSecondary = "style-module--primaryLiWithSecondary--JJmvz";
export var primaryButton = "style-module--primaryButton--rPjtQ";
export var primaryButtonActive = "style-module--primaryButtonActive--rykgn";
export var secondaryButton = "style-module--secondaryButton--uF8HX";
export var secondaryButtonActive = "style-module--secondaryButtonActive--c0kta";
export var spin = "style-module--spin--C3Jl5";