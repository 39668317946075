import React, { useEffect, useState, useRef } from 'react'
import { Path } from 'react-konva/lib/ReactKonvaCore'
import 'konva/lib/shapes/Path'

import Transformer from 'Components/Transformer'
import { useRoute } from 'Hooks/useRoute'
import { createHash } from 'Utils/createHash'
import { makeRGBA } from 'Utils/makeRGBA'

// import { touchCalc } from 'Utils/touchCalc'

/**
 * https://codesandbox.io/s/github/konvajs/site/tree/master/react-demos/transformer?from-embed=&file=/src/index.js
 */
const Route = () => {
    const route = useRoute()
    const routeRef = useRef()
    const routeIdRef = useRef()
    const transformerRef = React.useRef()

    const active = false

    // READD WHEN ALLOWING CUSTOM MOVEMENT OF ROUTE.
    // useEffect(() => {
    //     if (active && transformerRef.current) {
    //         // We need to attach transformer manually.
    //         transformerRef.current.nodes([routeRef.current])
    //         transformerRef.current.getLayer().batchDraw()
    //     }
    // }, [active, route.polyline])

    if (route.polyline) {
        // // If this is a new or different image,
        // // set up new sizing and create new hash.
        // if (route.hash !== routeIdRef.current) {
        //     const routeStringified = JSON.stringify(route.polyline)
        //     routeIdRef.current = createHash(routeStringified)
        //
        //     setRouteProps({
        //         ...route,
        //         scaleX: 1,
        //         scaleY: 1,
        //     })
        // }

        return (
            <>
                {route.polyline && (
                    <Path
                        ref={routeRef}
                        {...route}
                        data={route.polyline}
                        name={route.hash}
                        fill="transparent"
                        stroke={makeRGBA(route.color)}
                        strokeWidth={3}
                        strokeScaleEnabled={active}
                        strokeHitEnabled={active}
                        // hitFunc={(ctx, shape) => {
                        //     // ctx - is context wrapper
                        //     // shape - is instance of Konva.Shape, so it equals to "rect" variable
                        //     ctx.rect(
                        //         0,
                        //         0,
                        //         shape.getAttr('width'),
                        //         shape.getAttr('height')
                        //     )
                        //
                        //     // automatically fill shape from props and draw hit region
                        //     ctx.fillStrokeShape(shape)
                        // }}
                        scale={{
                            x: route.scaleX,
                            y: route.scaleY,
                        }}
                        listening={active}
                        draggable={active}
                        // onTouchMove={(e) => {
                        //     touchCalc({
                        //         e,
                        //         activeItem: route,
                        //         setActiveItem: setRouteProps,
                        //         transformerRef,
                        //     })
                        // }}
                        // onDragEnd={(e) => {
                        //     if (active) {
                        //         setRouteProps({
                        //             ...route,
                        //             x: e.target.x(),
                        //             y: e.target.y(),
                        //         })
                        //     }
                        // }}
                        // onTransformEnd={(e) => {
                        //     if (active) {
                        //         // transformer is changing scale of the node
                        //         // and NOT its width or height
                        //         // but in the store we have only width and height
                        //         // to match the data better we will reset scale on transform end
                        //         const node = routeRef.current
                        //         const scaleX = node.scaleX()
                        //         const scaleY = node.scaleY()
                        //
                        //         setRouteProps({
                        //             ...route,
                        //             x: node.x(),
                        //             y: node.y(),
                        //             scaleX: scaleX,
                        //             scaleY: scaleY,
                        //         })
                        //     }
                        // }}
                    />
                )}
                {
                    //<Transformer active={active} elem={transformerRef} />
                }
            </>
        )
    }

    return null
}

export default Route
