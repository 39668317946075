import React from 'react'
import { useDispatch } from 'react-redux'
import Button from 'Atoms/Button'
import { openDrawer } from 'State/features/drawerSlice'
import { button } from './style.module.scss'

const ChangeRoute = () => {
    const dispatch = useDispatch()

    return (
        <Button style={button} onClick={() => dispatch(openDrawer('routes'))}>
            Change Route
        </Button>
    )
}

export default ChangeRoute
