import React from 'react'
import UploadImage from 'Components/Buttons/UploadImage'
import RemoveImage from 'Components/Buttons/RemoveImage'
import { useImage } from 'Hooks/useImage'

const BaseImage = () => {
    const image = useImage()

    return (
        <>
            {!image.image && <UploadImage />}
            {image.image && <RemoveImage />}
        </>
    )
}

export default BaseImage
