import React from 'react'

import Canvas from 'Components/Canvas'
import AppWrapper from 'Containers/AppWrapper'
import Layout from 'Containers/Layout'
import Header from 'Components/Header'
import Control from 'Components/Control'
import Drawers from 'Components/Drawers'
import NavBar from 'Components/NavBar'

const App = () => {
    return (
        <>
            <Layout>
                <Header />
                <AppWrapper>
                    <Canvas />
                    <NavBar />
                    <Control />
                </AppWrapper>
            </Layout>
            <Drawers />
        </>
    )
}

export default App
