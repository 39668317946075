import React from 'react'
import { useDispatch } from 'react-redux'
import ColorPicker from 'Components/ColorPicker'
import { useCanvas } from 'Hooks/useCanvas'
import { setCanvasColor } from 'State/features/editorSlice'

const BaseColor = () => {
    const dispatch = useDispatch()
    const canvas = useCanvas()

    return (
        <ColorPicker
            color={canvas.color}
            setColor={(color) => dispatch(setCanvasColor(color))}
        />
    )
}

export default BaseColor
