import React from 'react'
import Button from 'Atoms/Button'
import Strava from 'SVG/strava.svg'
import { strava } from './style.module.scss'

const ConnectStrava = () => {
    const redirectUrl = `https://${process.env.GATSBY_BASE_URL}/strava-redirect`

    const handleOnClick = () => {
        window.location = `http://www.strava.com/oauth/authorize?client_id=${process.env.GATSBY_STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${redirectUrl}&approval_prompt=force&scope=read`
    }

    return (
        <Button onClick={handleOnClick} style={strava}>
            Connect with <Strava />
        </Button>
    )
}

export default ConnectStrava
