import React, { useEffect } from 'react'
import { Transformer as Tran } from 'react-konva/lib/ReactKonvaCore'
import 'konva/lib/shapes/Transformer'

/**
 * https://codesandbox.io/s/github/konvajs/site/tree/master/react-demos/transformer?from-embed=&file=/src/index.js
 */
const Transformer = ({ active, elem }) => {
    if (active) {
        useEffect(() => {
            elem.current.moveToTop()
        }, [active])

        return (
            <Tran
                ref={elem}
                borderStroke="white"
                anchorFill="white"
                anchorStroke="#736EFE"
                enabledAnchors={[
                    'top-left',
                    'top-right',
                    'bottom-left',
                    'bottom-right',
                ]}
                borderDash={[3, 3]}
                boundBoxFunc={(oldBox, newBox) => {
                    // limit resize
                    if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox
                    }
                    return newBox
                }}
            />
        )
    }

    return null
}

export default Transformer
