import React from 'react'
import {
    // AlphaPicker,
    CustomPicker,
} from 'react-color'
import { Slider, SlideItem } from 'Components/Slider'
import { swatchButton, swatchSpan } from './style.module.scss'
import { colorAlter } from 'Utils/colorAlter'
import { makeRGB } from 'Utils/makeRGBA'

const Swatch = ({ color, onClick, selected }) => {
    const swatchColor = selected
        ? makeRGB({ r: 0, g: 0, b: 0 })
        : makeRGB(colorAlter(color, -10))

    return (
        <button
            type="button"
            onClick={onClick}
            className={swatchButton}
            style={{
                backgroundColor: makeRGB(color),
                border: `2px solid ${swatchColor}`,
            }}
        >
            <span className={swatchSpan}>Color: {makeRGB(color)}</span>
        </button>
    )
}

const ColorPanels = CustomPicker(({ color, onChange }) => {
    const swatches = [
        { hex: '#736EFE', rgb: { r: 115, g: 110, b: 254 } },
        { hex: '#D9E3F0', rgb: { r: 217, g: 227, b: 240 } },
        { hex: '#F47373', rgb: { r: 244, g: 115, b: 115 } },
        { hex: '#697689', rgb: { r: 105, g: 118, b: 137 } },
        { hex: '#37D67A', rgb: { r: 55, g: 214, b: 122 } },
        { hex: '#2CCCE4', rgb: { r: 44, g: 204, b: 228 } },
        { hex: '#555555', rgb: { r: 85, g: 85, b: 85 } },
        { hex: '#dce775', rgb: { r: 220, g: 231, b: 117 } },
        { hex: '#ff8a65', rgb: { r: 255, g: 138, b: 101 } },
        { hex: '#ba68c8', rgb: { r: 186, g: 104, b: 200 } },
        { hex: '#fff', rgb: { r: 255, g: 255, b: 255 } },
    ]

    const handleOnChange = (selectedColor) => {
        onChange(selectedColor)
    }

    const doesColorMatch = (a, b) => {
        return makeRGB(a) === makeRGB(b)
    }

    return (
        <Slider>
            {swatches.map((swatchColor) => (
                <SlideItem key={swatchColor.hex}>
                    <Swatch
                        color={swatchColor.rgb}
                        selected={doesColorMatch(swatchColor.rgb, color)}
                        onClick={() => {
                            handleOnChange(swatchColor)
                        }}
                    />
                </SlideItem>
            ))}
        </Slider>
    )
})

const ColorPicker = ({ color, setColor }) => {
    const handleChange = (newColor) => {
        setColor(newColor.rgb)
    }

    return (
        <>
            <ColorPanels color={color} onChange={handleChange} />
            {
                // <AlphaPicker color={color} onChange={handleChange} />
            }
        </>
    )
}

export default ColorPicker
