import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import Button from 'Atoms/Button'
import { setImageFile } from 'State/features/editorSlice'
import { imageUpload } from 'Utils/imageHelpers'
import { button, imageInput } from './style.module.scss'

const UploadImage = () => {
    const dispatch = useDispatch()

    const imageUploadInput = useRef(null)

    const triggerUpload = () => {
        imageUploadInput.current.click()
    }

    const handleImageUpload = async (e) => {
        const result = await imageUpload(e)

        if (result.success) {
            // setError('')
            dispatch(setImageFile(result.payload))
        } else {
            // setError(result.error)
        }
    }

    return (
        <>
            <input
                accept="image/*"
                type="file"
                onChange={handleImageUpload}
                ref={imageUploadInput}
                className={imageInput}
            />
            <Button style={button} onClick={triggerUpload}>
                Upload Image
            </Button>
        </>
    )
}

export default UploadImage
