import React from 'react'
import { useDispatch } from 'react-redux'
import { useMenu } from 'Hooks/useMenu'
import {
    setSelectedPrimaryMenuItem,
    setSelectedSecondaryMenuItem,
} from 'State/features/menuSlice'
import {
    nav,
    primary,
    primaryPad,
    secondary,
    primaryLi,
    primaryButton,
    primaryButtonActive,
    secondaryLi,
    secondaryButton,
    secondaryButtonActive,
} from './style.module.scss'

const Primary = ({ items }) => {
    const dispatch = useDispatch()

    const activeItemHasChildren = Boolean(
        items.find(({ active }) => active)?.children
    )

    const primaryClass = activeItemHasChildren ? primaryPad : primary

    return (
        <ul className={primaryClass}>
            {items.map(({ active, children, name, slug }) => {
                const buttonClass = active ? primaryButtonActive : primaryButton

                return (
                    <li className={primaryLi} key={`nav-1-${slug}`}>
                        <button
                            className={buttonClass}
                            onClick={() =>
                                dispatch(setSelectedPrimaryMenuItem(slug))
                            }
                        >
                            {name}
                        </button>
                        {active && children && (
                            <Secondary parent={slug} items={children} />
                        )}
                    </li>
                )
            })}
        </ul>
    )
}

const Secondary = ({ parent, items }) => {
    const dispatch = useDispatch()

    return (
        <ul className={secondary}>
            {items.map(({ active, name, slug }) => {
                const buttonClass = active
                    ? secondaryButtonActive
                    : secondaryButton

                return (
                    <li className={secondaryLi} key={`nav-2-${slug}`}>
                        <button
                            className={buttonClass}
                            onClick={() =>
                                dispatch(
                                    setSelectedSecondaryMenuItem({
                                        slug,
                                        parent,
                                    })
                                )
                            }
                        >
                            {name}
                        </button>
                    </li>
                )
            })}
        </ul>
    )
}

const NavBar = () => {
    const menu = useMenu()
    return (
        <nav className={nav}>
            <Primary items={menu} />
        </nav>
    )
}

export default NavBar
