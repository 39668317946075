import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { geoEqualEarth, geoPath } from 'd3-geo'
import Button from 'Atoms/Button'
import { useCanvas } from 'Hooks/useCanvas'
import { setRoutePolyline, setInfoData } from 'State/features/editorSlice'
import { openRouteGpxFile } from 'Utils/gpxHelpers'
import { manual, uploadForm } from './style.module.scss'

const ConnectManual = ({ onSuccess }) => {
    const gpxUploadInput = useRef(null)
    const dispatch = useDispatch()
    const { width } = useCanvas()

    const triggerUpload = () => {
        gpxUploadInput.current.click()
    }

    const handleFileUpload = async (e) => {
        const result = await openRouteGpxFile(e)

        if (result.success) {
            const geojson = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: result.payload.markers,
                        },
                    },
                ],
            }

            // Padding
            const pathSize = width - 80

            const projection = geoEqualEarth().fitSize(
                [pathSize, pathSize],
                geojson
            )
            const svgData = geoPath().projection(projection)(geojson)

            dispatch(setRoutePolyline(svgData))
            dispatch(
                setInfoData({
                    name: result.payload.name,
                    distance: result.payload.dist,
                    elevation: result.payload.totalElev,
                })
            )
            if (onSuccess) {
                onSuccess()
            }
        } else {
            // ADD ERROR
        }
    }

    return (
        <>
            <Button style={manual} onClick={triggerUpload}>
                Manual Upload
            </Button>

            <input
                accept=".gpx"
                ref={gpxUploadInput}
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileUpload}
            />
        </>
    )
}

export default ConnectManual
