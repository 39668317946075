import haversine from 'haversine'

const readFile = (file) =>
    new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.readAsText(file)
    })

const limitDecimal = (num, lim) => {
    var x = parseFloat(num)
    return parseFloat(x.toFixed(lim))
}

const xmlToArray = (xml) => {
    const result = { name: '', markers: [], elev: [], totalElev: null, dist: 0 }

    // Loop through data into object.
    if (xml.documentElement.nodeName == 'gpx') {
        const trackWrap = xml.getElementsByTagName('trk')?.[0]
        if (trackWrap) {
            result.name =
                trackWrap.getElementsByTagName('name')?.[0]?.textContent || ''
        }

        for (let item of xml.getElementsByTagName('trkpt')) {
            const eleChild = item.getElementsByTagName('ele')

            const lat = limitDecimal(item.getAttribute('lat'), 17)
            const lon = limitDecimal(item.getAttribute('lon'), 17)

            const thisPoint = [lon, lat]

            if (eleChild[0]) {
                result.elev.push(limitDecimal(eleChild[0].textContent, 4))
            }

            result.markers.push(thisPoint)
        }
    } else {
        throw 'Not a GPX file.'
    }

    // Get total elevation
    if (result.elev) {
        // Loop through elev array. Add up when elevation goes up.
        let lastPoint = null
        let totalElev = 0
        result.elev.forEach((currentEl) => {
            if (!lastPoint) {
                lastPoint = currentEl
            } else {
                // If current point higher than last point?
                const diff = currentEl - lastPoint
                if (diff > 0) {
                    totalElev = diff + totalElev
                }
                lastPoint = currentEl
            }
        })

        if (totalElev > 0) {
            result.totalElev =
                Math.round((totalElev + Number.EPSILON) * 100) / 100
        } else {
            result.totalElev = ''
        }
    }

    let lastMarkers
    result.markers.forEach((markers, i) => {
        if (!lastMarkers) {
            // If we have no start point, set a start point.
            lastMarkers = markers
        } else {
            // Now we have a previous marker, lets measure!
            result.dist =
                result.dist +
                haversine(lastMarkers, markers, {
                    unit: 'meter',
                    format: '[lon,lat]',
                })

            // Reset last markers for next loop
            lastMarkers = markers
        }
    })

    const metres = result.dist / 1000

    // Round up total distance.
    result.dist = Math.round((metres + Number.EPSILON) * 100) / 100

    return result
}

export const openRouteGpxFile = async (event) => {
    if (event.target.files[0]) {
        const file = event.target.files[0]

        const text = await readFile(file)

        const xmlDoc = new DOMParser().parseFromString(text, 'text/xml')

        try {
            const res = xmlToArray(xmlDoc)

            // If we have markers, lets add
            if (res) {
                return { success: true, payload: res }
            }
        } catch {
            return { success: false, error: 'Please upload a valid GPX file.' }
        }
    }
    return { success: false, error: '' }
}
