import React from 'react'
import { useDispatch } from 'react-redux'
import { useAuth } from 'Hooks/useAuth'
import { useRoute } from 'Hooks/useRoute'
import ChangeRoute from 'Components/Buttons/ChangeRoute'
import ConnectStrava from 'Components/Buttons/ConnectStrava'
import ConnectManual from 'Components/Buttons/ConnectManual'
import ColorPicker from 'Components/ColorPicker'
import { setRouteColor } from 'State/features/editorSlice'

const Routes = () => {
    const { strava } = useAuth()
    const dispatch = useDispatch()
    const route = useRoute()

    if (!strava && !route.polyline) {
        return (
            <>
                <ConnectStrava />
                <ConnectManual />
            </>
        )
    }

    return (
        <>
            <p>Select the route colour</p>
            <ColorPicker
                color={route.color}
                setColor={(color) => dispatch(setRouteColor(color))}
            />
            <br />
            <ChangeRoute />
        </>
    )
}

export default Routes
