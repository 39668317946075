import React from 'react'

import { button } from './style.module.scss'

const Button = ({ children, onClick, style }) => {
    const classes = [button]
    if (style) {
        classes.push(style)
    }

    return (
        <button
            type="button"
            className={classes.join(' ')}
            onClick={onClick}
            style={{ style }}
        >
            {children}
        </button>
    )
}

export default Button
