import React, { useEffect, useRef } from 'react'
import loadable from '@loadable/component'
import { useDrawer } from 'Hooks/useDrawer'

const RouteDrawer = loadable(() => import('./RouteDrawer'))

const Drawers = () => {
    const drawer = useDrawer()
    const hasRendered = useRef(false)

    useEffect(() => {
        useRef.current = true
    }, [])

    if (useRef.current) {
        return <RouteDrawer isOpen={drawer === 'routes'} />
    }
    return null
}

export default Drawers
