import React, { useRef } from 'react'
import CanvasInner from './CanvasInner'
import { artspaceSizer, artSpaceInner } from './style.module.scss'

const Canvas = () => {
    const canvasRef = useRef(null)

    return (
        <div
            className={artspaceSizer}
            ref={canvasRef}
            style={{ backgroundColor: '#736EFE' }}
        >
            <div className={artSpaceInner}>
                <CanvasInner elem={canvasRef} />
            </div>
        </div>
    )
}

export default Canvas
