import React from 'react'
import ContentWrapper from 'Containers/ContentWrapper'
import { useCanvas } from 'Hooks/useCanvas'
import { useMenuActiveItem } from 'Hooks/useMenu'
import { useWindowSize } from 'Hooks/useWindowSize'
import BaseColor from './screens/BaseColor'
import BaseImage from './screens/BaseImage'
import Info from './screens/Info'
import Layout from './screens/Layout'
import Routes from './screens/Routes'
import { control } from './style.module.scss'

const InnerPanel = () => {
    const activeItem = useMenuActiveItem()

    switch (activeItem) {
        case 'route':
            return <Routes />

        case 'base-image':
            return <BaseImage />

        case 'base-colour':
            return <BaseColor />

        case 'info':
            return <Info />

        case 'layout':
            return <Layout />

        default:
            return null
    }
}

const Control = () => {
    const { width } = useCanvas()
    const { height } = useWindowSize()
    const headerHeight = 22
    const navHeight = 50

    const maxHeight = height - width - navHeight - headerHeight

    return (
        <div className={control} style={{ maxHeight: maxHeight || 'auto' }}>
            <ContentWrapper>
                <InnerPanel />
            </ContentWrapper>
        </div>
    )
}

export default Control
