import React, { useRef } from 'react'

import {
    wrapStyle,
    inputStyle,
    labelStyle,
    errorStyle,
} from './style.module.scss'

const Input = React.forwardRef(
    ({ error, id, label, onChange, value, type = 'text' }, ref) => {
        const _ref = useRef(null)
        const inputRef = ref || _ref

        var invalidChars = ['-', '+', 'e']

        const handleKeyPress = (e) => {
            if (type === 'number') {
                if (invalidChars.includes(e.key)) {
                    e.preventDefault()
                }
            }
        }

        const handleOnPaste = (e) => {
            if (type === 'number') {
                // Handle this later when we care.
                // Stop e, + and - getting pasted.
            }
        }

        return (
            <div
                active-attr={value ? 'true' : null}
                className={wrapStyle}
                error-attr={error ? 'true' : null}
            >
                <input
                    ref={inputRef}
                    className={inputStyle}
                    value={value}
                    id={id}
                    name={id}
                    onPaste={handleOnPaste}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => onChange(e.target.value)}
                    type={type}
                />
                <label className={labelStyle} htmlFor={id}>
                    {label}
                </label>
                <span className={errorStyle}>{error}</span>
            </div>
        )
    }
)

export default Input
