export const readFile = (file) =>
    new Promise((resolve) => {
        var reader = new FileReader()
        reader.onload = function (event) {
            var img = new Image()
            img.onload = function () {
                resolve({
                    width: img.width,
                    height: img.height,
                    data: event.target.result,
                })
            }
            img.src = event.target.result
        }
        reader.readAsDataURL(file)
    })

export const imageUpload = async (event) => {
    const acceptedImageTypes = ['jpeg', 'jpg', 'png']

    const fileName = event.target.value
    const fileExtension = fileName.split('.').pop().toLowerCase()

    if (!acceptedImageTypes.includes(fileExtension)) {
        return { success: false, error: 'Image should be jpeg or png' }
    }

    if (event.target.files[0]) {
        const file = event.target.files[0]

        const image = await readFile(file)

        return { success: true, payload: image }
    }
    return { success: false, error: '' }
}
