import React, { useEffect } from 'react'

import './style.scss'

const Layout = ({ children }) => {
    useEffect(() => {
        const arcTimerSetter = setTimeout(() => {
            // const headID = document.getElementsByTagName('head')[0]
            // const newScript = document.createElement('script')
            // newScript.type = 'text/javascript'
            // newScript.src =
            //     'https://arc.io/widget.min.js#WrxccT2crAmKvqcpei9Qkw'
            // headID.appendChild(newScript)
            // setTimerComplete(true)
        }, 5000)

        return () => {
            clearTimeout(arcTimerSetter)
        }
    })

    return (
        <div className="globalWrap">
            <div className="layout">{children}</div>
        </div>
    )
}

export default Layout
