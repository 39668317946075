import React from 'react'
import { slider, list, inner, item } from './style.module.scss'

export const Slider = ({ children }) => (
    <div className={slider}>
        <div className={inner}>
            <ul className={list}>{children}</ul>
        </div>
    </div>
)

export const SlideItem = ({ children }) => <li className={item}>{children}</li>
