import React, { useEffect, useRef } from 'react'
import { connect, ReactReduxContext, Provider } from 'react-redux'
import { Stage, Layer } from 'react-konva/lib/ReactKonvaCore'
import Konva from 'konva/lib/Core'
import { setCanvasWidth } from 'State/features/editorSlice'
import BackgroundColor from '../BackgroundColor'
import BackgroundImage from '../BackgroundImage'
import InfoText from '../InfoText'
import Route from '../Route'
import { artspaceSizer } from './style.module.scss'

Konva.hitOnDragEnabled = true
Konva.captureTouchEventsEnabled = true

const CanvasInner = ({ elem, setCanvas, width }) => {
    useEffect(() => {
        setCanvas(elem.current.offsetWidth)
    }, [elem])

    return (
        <ReactReduxContext.Consumer>
            {({ store }) => (
                <Stage width={width} height={width}>
                    <Provider store={store}>
                        <Layer>
                            <BackgroundColor />
                            <BackgroundImage />
                            <Route />
                            <InfoText />
                        </Layer>
                    </Provider>
                </Stage>
            )}
        </ReactReduxContext.Consumer>
    )
}

const mapStateToProps = (state) => ({ width: state.editor.canvas.width })
const mapDispatchToProps = (dispatch) => {
    return {
        setCanvas: (val) => dispatch(setCanvasWidth(val)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CanvasInner)
