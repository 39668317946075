import React from 'react'
import { useDispatch } from 'react-redux'
import Button from 'Atoms/Button'
import { removeImageFile } from 'State/features/editorSlice'
import { button } from './style.module.scss'

const RemoveImage = () => {
    const dispatch = useDispatch()

    return (
        <Button
            style={button}
            onClick={() => {
                dispatch(removeImageFile())
            }}
        >
            Remove Image
        </Button>
    )
}

export default RemoveImage
