import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Image } from 'react-konva/lib/ReactKonvaCore'
import 'konva/lib/shapes/Image'
import { default as getImage } from 'use-image'
import Transformer from 'Components/Transformer'

import { useMenuActiveItem } from 'Hooks/useMenu'
import { useImage } from 'Hooks/useImage'

import useClickOutside from 'Hooks/useClickOutside'

import { setImagePosition } from 'State/features/editorSlice'

/**
 * https://codesandbox.io/s/github/konvajs/site/tree/master/react-demos/transformer?from-embed=&file=/src/index.js
 */
const BackgroundImage = () => {
    const dispatch = useDispatch()
    const imageRef = useRef()
    const GESTURE = useRef({
        angleDifference: null,
        distanceDifference: null,
        initialAngle: null,
        initialDistance: null,
        lastCenter: null,
        lastDistance: null,
    })
    const imageIdRef = useRef()
    const transformerRef = React.useRef()

    const image = useImage()
    const [imageElem] = getImage(image.image)
    const [draggable, setDraggable] = useState(active)

    const activeMenu = useMenuActiveItem()

    const active = activeMenu === 'base-image'

    const setImageState = (newState) => {
        dispatch(setImagePosition({ ...image, ...newState }))
    }

    // useEffect(() => {
    //     if (image.image) {
    //         imageRef.current.zIndex(1)
    //     }
    // }, [image.image])

    useEffect(() => {
        if (active && transformerRef.current) {
            // We need to attach transformer manually.
            transformerRef.current.nodes([imageRef.current])
            transformerRef.current.getLayer().batchDraw()
        }
        setDraggable(active)
    }, [active, image.image])

    if (image.image) {
        // If this is a new or different image,
        // set up new sizing and apply hash.
        if (image.hash !== imageIdRef.current) {
            imageIdRef.current = image.hash

            // Get scale ratio.
            const height = image.height
            const width = image.width
            const hRatio = defaultImageSize / height
            const wRatio = defaultImageSize / width
            const ratio = Math.min(hRatio, wRatio)

            setImageState({ scaleX: ratio, scaleY: ratio })
        }

        function clean() {
            GESTURE.current.angleDifference = null
            GESTURE.current.distanceDifference = null
            GESTURE.current.initialAngle = null
            GESTURE.current.initialDistance = null
            GESTURE.current.lastCenter = null
            GESTURE.current.lastDistance = null
        }

        function calcDistance(data) {
            var A = data[0],
                B = data[1]
            return (
                Math.sqrt(
                    (B.x - A.x) * (B.x - A.x) + (B.y - A.y) * (B.y - A.y)
                ) * -1
            )
        }

        function getDistance(p1, p2) {
            return Math.sqrt(
                Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2)
            )
        }

        function getCenter(p1, p2) {
            return {
                x: (p1.x + p2.x) / 2,
                y: (p1.y + p2.y) / 2,
            }
        }

        function calcAngle(data) {
            var A = data[0],
                B = data[1],
                angle =
                    Math.atan(((B.y - A.y) * -1) / (B.x - A.x)) *
                    (180 / Math.PI)
            return angle < 0 ? angle + 180 : angle
        }

        function handleAngle(touch) {
            var angle = parseInt(calcAngle(touch), 10),
                diff = parseInt(GESTURE.current.initialAngle - angle, 10),
                i,
                symbol

            // If angle is less than 4, this is probably a pinch and not
            // a rotate.
            if (Math.abs(diff) > 4 || GESTURE.current.angleDifference !== 0) {
                i = 0
                symbol = GESTURE.current.angleDifference < 0 ? '-' : '+'
                while (
                    Math.abs(diff - GESTURE.current.angleDifference) > 90 &&
                    i++ < 10
                ) {
                    eval('diff ' + symbol + '= 180;')
                }
                GESTURE.current.angleDifference = parseInt(diff, 10)
                return GESTURE.current.angleDifference
            }

            return 0
        }

        const rotatePoint = ({ x, y }, rad) => {
            const rcos = Math.cos(rad)
            const rsin = Math.sin(rad)
            return { x: x * rcos - y * rsin, y: y * rcos + x * rsin }
        }

        function rotateAroundCenter(node, rotation) {
            //current rotation origin (0, 0) relative to desired origin - center (node.width()/2, node.height()/2)
            const topLeft = { x: -node.width() / 2, y: -node.height() / 2 }
            const current = rotatePoint(
                topLeft,
                Konva.getAngle(node.rotation())
            )

            const rotated = rotatePoint(topLeft, Konva.getAngle(rotation))

            const dx = rotated.x - current.x,
                dy = rotated.y - current.y

            return {
                rotation: rotation,
                x: node.x() + dx,
                y: node.y() + dy,
            }
        }

        const getTouches = (e) => {
            return { f1: e.evt.touches[0], f2: e.evt.touches[1] }
        }

        const isTwoFingers = (e) => {
            const touches = getTouches(e)

            if (touches.f1 && touches.f2) {
                return true
            }

            return false
        }

        const touchLocation = (e) => {
            const { f1, f2 } = getTouches(e)

            return {
                f1: {
                    x: f1.clientX,
                    y: f1.clientY,
                },
                f2: {
                    x: f2.clientX,
                    y: f2.clientY,
                },
            }
        }

        return (
            <>
                <Image
                    ref={imageRef}
                    x={image.x}
                    y={image.y}
                    rotation={image.rotation}
                    width={image.width}
                    height={image.height}
                    name={image.hash}
                    image={imageElem}
                    draggable={draggable}
                    onTouchStart={(e) => {
                        if (active) {
                            e.evt.preventDefault()

                            if (isTwoFingers(e)) {
                                const { f1, f2 } = touchLocation(e)

                                GESTURE.current.initialAngle = parseInt(
                                    calcAngle([f1, f2]),
                                    10
                                )
                                GESTURE.current.initialDistance = parseInt(
                                    calcDistance([f1, f2]),
                                    10
                                )
                                GESTURE.current.angleDifference = 0
                                GESTURE.current.distanceDifference = 0
                            }
                        }
                    }}
                    onTouchMove={(e) => {
                        if (active) {
                            // https://gist.github.com/danklammer/dc42eb8511e70abf5e83

                            e.evt.preventDefault()

                            if (isTwoFingers(e)) {
                                // if the stage was under Konva's drag&drop
                                // we need to stop it, and implement our own pan logic with two pointers
                                const node = imageRef.current

                                const scaleX = node.scaleX()
                                const scaleY = node.scaleY()
                                const x = node.x()
                                const y = node.y()
                                const origWidth = node.width()
                                const origHeight = node.height()

                                setDraggable(false)

                                const { f1, f2 } = touchLocation(e)

                                if (!GESTURE.current.lastCenter) {
                                    GESTURE.current.lastCenter = getCenter(
                                        f1,
                                        f2
                                    )
                                    return
                                }

                                let newCenter = getCenter(f1, f2)

                                let dist = getDistance(f1, f2)

                                if (!GESTURE.current.lastDistance) {
                                    GESTURE.current.lastDistance = dist
                                }

                                // local coordinates of center point
                                let pointTo = {
                                    x: (newCenter.x - x) / scaleX,
                                    y: (newCenter.y - y) / scaleX,
                                }

                                // var scale = scaleX * (dist / lastDist)
                                let scale =
                                    scaleX *
                                    (dist / GESTURE.current.lastDistance)

                                // calculate new position of the stage
                                let dx =
                                    newCenter.x - GESTURE.current.lastCenter.x
                                let dy =
                                    newCenter.y - GESTURE.current.lastCenter.y

                                let newPos = {
                                    x: newCenter.x - pointTo.x * scale + dx,
                                    y: newCenter.y - pointTo.y * scale + dy,
                                }

                                let angle = handleAngle([f1, f2])
                                if (angle === 0) {
                                    setImageState({
                                        x: newPos.x,
                                        y: newPos.y,
                                        height: Math.max(origHeight * scale),
                                        width: Math.max(origWidth * scale),
                                    })
                                } else {
                                    setImageState(
                                        rotateAroundCenter(node, angle)
                                    )
                                }

                                GESTURE.current.lastCenter = newCenter
                                GESTURE.current.lastDistance = dist
                            }
                        }
                    }}
                    onTouchEnd={() => {
                        if (active) {
                            setDraggable(true)
                            const node = imageRef.current
                            // Reset scale.
                            node.scaleX(1)
                            node.scaleY(1)

                            clean()
                        }
                    }}
                    onDragEnd={(e) => {
                        if (active) {
                            setImageState({
                                x: e.target.attrs.x,
                                y: e.target.attrs.y,
                            })
                        }
                    }}
                    onTransformEnd={(e) => {
                        if (active) {
                            // Link: https://konvajs.org/docs/react/Transformer.html
                            // transformer is changing scale of the node
                            // and NOT its width or height
                            // but in the store we have only width and height
                            // to match the data better we will reset scale on transform end
                            const node = imageRef.current
                            const scaleX = node.scaleX()
                            const scaleY = node.scaleY()

                            // Reset scale to 1.
                            node.scaleX(1)
                            node.scaleY(1)

                            setImageState({
                                x: node.x(),
                                y: node.y(),
                                height: Math.max(node.height() * scaleY),
                                width: Math.max(node.width() * scaleX),
                            })
                        }
                    }}
                />

                <Transformer active={draggable} elem={transformerRef} />
            </>
        )
    }

    return null
}

export default BackgroundImage
