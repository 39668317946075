import React from 'react'
import { Text } from 'react-konva/lib/ReactKonvaCore'
import 'konva/lib/shapes/Text'

import { useInfo } from 'Hooks/useInfo'
import { makeRGBA } from 'Utils/makeRGBA'

const InfoText = () => {
    const { name, distance, elevation, color, unit } = useInfo()

    return (
        <>
            {name !== '' && (
                <Text
                    text={name}
                    fontSize={30}
                    fill={makeRGBA(color)}
                    x={20}
                    y={20}
                />
            )}
            {distance !== '' && (
                <Text
                    text={`${distance}${unit === 'metric' ? 'km' : 'miles'}`}
                    fontSize={30}
                    fill={makeRGBA(color)}
                    x={20}
                    y={60}
                />
            )}
            {elevation !== '' && (
                <Text
                    text={`${elevation}${unit === 'metric' ? 'm' : 'ft'}`}
                    fontSize={30}
                    fill={makeRGBA(color)}
                    x={20}
                    y={100}
                />
            )}
        </>
    )
}

export default InfoText
