import React from 'react'
import { Rect } from 'react-konva/lib/ReactKonvaCore'
import 'konva/lib/shapes/Rect'
import { useCanvas } from 'Hooks/useCanvas'
import { colorAlter } from 'Utils/colorAlter'
import { makeRGBA } from 'Utils/makeRGBA'

const BackgroundColor = () => {
    const { color, width } = useCanvas()

    return (
        <Rect
            x={0}
            y={0}
            width={width}
            height={width}
            fillLinearGradientStartPoint={{
                x: 0,
                y: 0,
            }}
            fillLinearGradientEndPoint={{
                x: width,
                y: width,
            }}
            fillLinearGradientColorStops={[
                0,
                makeRGBA(colorAlter(color, 20)),
                0.3,
                makeRGBA(color),
                0.8,
                makeRGBA(colorAlter(color, -10)),
                1,
                makeRGBA(colorAlter(color, -20)),
            ]}
        />
    )
}

export default BackgroundColor
