export const colorAlter = (color, percent) => {
    let r = parseInt((color.r * (100 + percent)) / 100)
    let g = parseInt((color.g * (100 + percent)) / 100)
    let b = parseInt((color.b * (100 + percent)) / 100)

    r = r > 0 ? r : 0
    g = g > 0 ? g : 0
    b = b > 0 ? b : 0

    r = r < 255 ? r : 255
    g = g < 255 ? g : 255
    b = b < 255 ? b : 255

    const newColor = {
        r,
        g,
        b,
        a: color.a,
    }

    return newColor
}
