import React from 'react'

import { heading1, heading2, heading3 } from './style.module.scss'

const ControlTitle = ({ children, h }) => {
    if (h === '1') {
        return <h1 className={heading1}>{children}</h1>
    }
    if (h === '2') {
        return <h2 className={heading2}>{children}</h2>
    }
    if (h === '3') {
        return <h3 className={heading3}>{children}</h3>
    }
    return null
}

export default ControlTitle
