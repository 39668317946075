import React from 'react'
import { useDispatch } from 'react-redux'
import ControlTitle from 'Atoms/ControlTitle'
import Input from 'Atoms/Input'
import ColorPicker from 'Components/ColorPicker'
import { useInfo } from 'Hooks/useInfo'
import { setInfoData } from 'State/features/editorSlice'

const Info = () => {
    const { color, name, distance, elevation, unit } = useInfo()
    const dispatch = useDispatch()

    const handleInputChange = (key, val) => {
        dispatch(setInfoData({ [key]: val }))
    }

    const handleColorChange = (c) => {
        dispatch(setInfoData({ color: c }))
    }

    return (
        <>
            <ControlTitle h="1">Update Route Info</ControlTitle>
            <Input
                id="route-name"
                label="Route Name"
                onChange={(val) => handleInputChange('name', val)}
                value={name}
                type="text"
            />
            <Input
                id="route-distance"
                label={`Route Distance (${unit === 'metric' ? 'km' : 'miles'})`}
                onChange={(val) => handleInputChange('distance', val)}
                value={distance}
                type="number"
            />
            <Input
                id="route-elevation"
                label={`Route Elevation (${unit === 'metric' ? 'm' : 'ft'})`}
                onChange={(val) => handleInputChange('elevation', val)}
                value={elevation}
                type="number"
            />
            <ColorPicker color={color} setColor={(c) => handleColorChange(c)} />
        </>
    )
}

export default Info
