import React from 'react'
import ControlTitle from 'Atoms/ControlTitle'

const Layout = () => {
    return (
        <>
            <ControlTitle h="1">Select Layout</ControlTitle>
        </>
    )
}

export default Layout
