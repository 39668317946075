import { useSelector } from 'react-redux'
import { getMenu } from 'State/features/menuSlice'

export const useMenu = () => {
    const menu = useSelector(getMenu)
    return menu
}

export const useMenuActiveItem = () => {
    const menu = useSelector(getMenu)
    const currentActive = menu.find(({ active }) => active)

    if (currentActive.children) {
        const activeChild = currentActive.children.find(({ active }) => active)
        return `${currentActive.slug}-${activeChild.slug}`
    }

    return currentActive.slug
}
